@import url(https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, html {
  height: 100%;
}
.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
  z-index: 1;
}

.App-header {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

.App-body {
  position: absolute;
  color: white;
  /*display: flex;
  flex-direction: column;*/
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7) !important;
  padding-top: 120px;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.background,
.redPaddle,
.bluePaddle,
.ball {
  position: absolute;
  border-radius: 5px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  will-change: transform;
}

.background {
  width: 150vw;
  height: 200vh;
  background-image: url(../../static/media/background.050ed6b4.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.redPaddle {
  width: 40ch;
  height: 35ch;
  background-image: url(../../static/media/redPaddle.4b01750e.png);
}

.bluePaddle {
  width: 40ch;
  height: 35ch;
  background-image: url(../../static/media/bluePaddle.cad282b5.png);
}

.ball {
  width: 5ch;
  height: 5ch;
  background-image: url(../../static/media/ball.ab747d40.png);
}

.Spring-container {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: fixed;
}

.mainButton {
  display: inline-block;
  z-index: 1 !important;
  padding: 20px 50px 20px 50px;
  width: 400px;
  text-decoration: none;
  transition: 0.2s;
}

.mainButton:hover {
  opacity: 0.8;
  text-decoration: none;
}

.Main-buttons-container {
  width: 850px;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 5px 0;
  z-index: 1;
}

h1 {
  font-family: 'Fugaz One', cursive;
  z-index: 1;
  font-size: calc(10px + 5.5vmin);
  text-shadow: 0px 0px 5px rgb(255, 255, 225);
}

h2 {
  font-family: 'Fugaz One', cursive;
  z-index: 1;
  font-size: calc(10px + 4vmin);
  text-shadow: 2px 2px 5px rgb(236, 110, 236);
  margin: 0 auto 0 auto;
}

h3 {
  font-size: calc(10px + 1vmin);
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  font-weight:600;
}

h4 {
  font-family: 'Fugaz One', cursive;
  z-index: 1;
  font-size: calc(10px + 2.5vmin);
  color: rgba(255, 255, 255, 0.2);
  margin: 0 auto 0 auto;
}

.Information-section {
  /*text-align: center;
  display: flex;
  justify-content: space-between;*/
  padding: 0 7.5vw 0 7.5vw;
  z-index: 1;
  overflow: hidden;
  position: relative;
}

.Information-section-text {
  position: absolute;
  width: 60%;
  text-align: left;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);   
}

.FAQ-accordion {
  width: 50%; 
  left: 50%;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
  margin-top: 25;
}

.FAQ-accordion-card {
  background-color: rgb(15, 15, 15);
}

.FAQ-accordion-card-header {
  background-color: rgb(15, 15, 15);
  text-align: left;
  font-weight: 600;
}

.FAQ-accordion-card-header:hover {
  cursor: pointer;
}

.FAQ-accordion-card-body {
  background-color: rgb(10, 10, 10);
  text-align: left;
}

a:link {
  color: rgb(236, 110, 236);
  font-weight: 600;
}

a:visited {
  color: rgb(236, 110, 236);
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .background {
    width: 200vw;
    height: 300vh;
    background-image: url(../../static/media/background.050ed6b4.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .Main-buttons-container {
    width: 375px;
    text-align: center;
    display: inline-block;
  }

  .Information-section > img {
    float: none !important;
    width: 100% !important;
  }

  .Information-section > div {
    position: relative !important;
    width: 100% !important;
    right: auto !important;
    top: auto !important;
    -webkit-transform: none !important;
            transform: none !important;
    padding: 0 !important;
    margin: 3vh 0 8vh 0 !important;
  }

  .FAQ-accordion {
    width: auto;
    left: 0;
    -webkit-transform: none;
            transform: none;
  }
}
